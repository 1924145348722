.flashit {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-animation: flash linear 1s infinite;
    animation: flash linear 1s infinite;
}
@-webkit-keyframes flash {
    0% { opacity: 1; }
    50% { opacity: .1; }
    100% { opacity: 1; }
}
@keyframes flash {
    0% { opacity: 1; }
    50% { opacity: .1; }
    100% { opacity: 1; }
}

